import React from 'react';

const DropDown = ({ items, label, onChange, disabled }) => {
  return (
    <div className="relative">
      <label className="block text-sm font-medium text-gray-700">{label}</label>
      <select
        className="mt-1 block w-64 px-2 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
      >
        <option value="">Select...</option>
        {items.map((item) => (
          <option key={item._id} value={item._id}>
            {item.name}{item.title}
          </option>
        ))}
      </select>
    </div>
  );
};

export default DropDown;