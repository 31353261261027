import React from 'react';
import { Bot, Code, BarChart, Megaphone, PenTool, Layout, Database, UsersRound, Rocket, Shield } from 'lucide-react';

const ServicesPage = () => {
  const services = [
    {
      icon: <Bot className="w-6 h-6" />,
      title: "Social Media Management",
      description: "AI-powered social media strategies that engage your audience and drive growth.",
      features: [
        "Content calendar planning",
        "AI-driven post optimization",
        "Engagement monitoring",
        "Performance analytics",
        "Competitor analysis"
      ]
    },
    {
      icon: <Megaphone className="w-6 h-6" />,
      title: "Digital Marketing",
      description: "Comprehensive digital marketing campaigns that deliver measurable results.",
      features: [
        "SEO optimization",
        "PPC management",
        "Email marketing",
        "Content strategy",
        "Marketing automation"
      ]
    },
    {
      icon: <Code className="w-6 h-6" />,
      title: "Web Development",
      description: "Custom web solutions that transform your digital presence.",
      features: [
        "Responsive design",
        "E-commerce solutions",
        "CMS development",
        "API integration",
        "Performance optimization"
      ]
    },
    {
      icon: <Database className="w-6 h-6" />,
      title: "CRM Management",
      description: "Streamlined customer relationship management for better business outcomes.",
      features: [
        "Customer data analysis",
        "Pipeline management",
        "Lead tracking",
        "Automation workflows",
        "Integration services"
      ]
    },
    {
      icon: <PenTool className="w-6 h-6" />,
      title: "Content Creation",
      description: "AI-assisted content that engages and converts your target audience.",
      features: [
        "Blog writing",
        "Social media content",
        "Email newsletters",
        "SEO copywriting",
        "Visual content design"
      ]
    },
    {
      icon: <Layout className="w-6 h-6" />,
      title: "Mobile App Development",
      description: "Custom mobile applications that extend your digital reach.",
      features: [
        "Native development",
        "Cross-platform apps",
        "UI/UX design",
        "App maintenance",
        "Performance monitoring"
      ]
    }
  ];

  const processSteps = [
    {
      icon: <UsersRound className="w-6 h-6" />,
      title: "Discovery",
      description: "We begin with a thorough consultation to understand your business needs and objectives."
    },
    {
      icon: <BarChart className="w-6 h-6" />,
      title: "Strategy",
      description: "Develop a customized strategy aligned with your goals and industry best practices."
    },
    {
      icon: <Rocket className="w-6 h-6" />,
      title: "Implementation",
      description: "Execute the strategy with continuous monitoring and optimization."
    },
    {
      icon: <Shield className="w-6 h-6" />,
      title: "Support",
      description: "Provide ongoing support and refinement to ensure long-term success."
    }
  ];

  return (
    <div className="min-h-screen pt-20">
      {/* Hero Section */}
      <section className="relative bg-gradient-to-br from-[#223362] to-[#41B07D] text-white py-20">
        <div className="absolute inset-0 bg-black/20"></div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h1 className="text-4xl md:text-5xl font-bold mb-6">
            Comprehensive Digital Services
          </h1>
          <p className="text-xl text-gray-100 max-w-2xl mx-auto">
            From AI-powered marketing to custom software development, we provide the services 
            you need to succeed in the digital age.
          </p>
        </div>
      </section>

      {/* Services Grid */}
      <section className="py-20 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
            {services.map((service, index) => (
              <div 
                key={index}
                className="bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 p-8"
              >
                <div className="w-12 h-12 bg-[#41B07D]/10 rounded-lg flex items-center justify-center text-[#41B07D] mb-6">
                  {service.icon}
                </div>
                <h3 className="text-xl font-bold text-[#223362] mb-4">{service.title}</h3>
                <p className="text-gray-600 mb-6">{service.description}</p>
                <ul className="space-y-3">
                  {service.features.map((feature, fIndex) => (
                    <li key={fIndex} className="flex items-center text-gray-600">
                      <div className="w-1.5 h-1.5 rounded-full bg-[#41B07D] mr-2"></div>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Process Section */}
      <section className="py-20 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-3xl font-bold text-[#223362] mb-4">Our Process</h2>
            <p className="text-gray-600 max-w-2xl mx-auto">
              We follow a proven methodology to ensure successful implementation and 
              delivery of our services.
            </p>
          </div>

          <div className="grid md:grid-cols-4 gap-8">
            {processSteps.map((step, index) => (
              <div key={index} className="text-center">
                <div className="w-16 h-16 bg-[#41B07D]/10 rounded-full flex items-center justify-center text-[#41B07D] mx-auto mb-6">
                  {step.icon}
                </div>
                <h3 className="text-xl font-bold text-[#223362] mb-3">{step.title}</h3>
                <p className="text-gray-600">{step.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-[#223362] py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold text-white mb-6">
            Ready to Get Started?
          </h2>
          <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
            Let's discuss how our services can help transform your business.
          </p>
          <a
            href="/contact"
            className="inline-flex items-center px-8 py-3 bg-[#41B07D] text-white rounded-lg hover:bg-[#41B07D]/90 transition-all duration-300 shadow-lg hover:shadow-xl"
          >
            Schedule a Consultation
          </a>
        </div>
      </section>
    </div>
  );
};

export default ServicesPage;