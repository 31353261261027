import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "../assets/ouitrend.png";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setLogout } from "../features/auth/authSlice";



const Sidebar = ({ routes, role }) => {
  const [isOpen, setIsOpen] = useState(true);
  const [ind, setInd] = useState(0);

  const dispatch = useDispatch()

  const user = useSelector((state) => state.auth.user)



  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const navigate = useNavigate();

  const logout = () => {
    dispatch(setLogout());
    navigate("/login");
  };

  const handleClick = (index) => {
    setInd(index);
    console.log(index);
    // navigate(link);
  };

  return (
    <div class="flex h-screen flex-col justify-between border-e bg-white">
      <div class="px-4 py-6">
        <img
          src={Logo}
          alt="Company Logo"
          className="h-10 w-auto max-w-full object-contain"
        />
        OuiTrend
      
        <ul class="mt-6 space-y-1">



          {routes.map((route) => (
            <li>
            <button
              onClick={() => {
                handleClick(route.id);
                navigate(route.link)
              }}
              href={route}
              className={`block rounded-lg w-full text-start  ${
                ind === route.id
                  ? "bg-gray-100 text-gray-700 "
                  : "bg-white text-gray-500"
              } px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-700`}
            >
              {route.text}
            </button>
          </li>
          )
             
 
            
          )}









          



       





          <li>
            <details class="group [&_summary::-webkit-details-marker]:hidden">
              <summary class="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700">
                <span class="text-sm font-medium"> Teams </span>

                <span class="shrink-0 transition duration-300 group-open:-rotate-180">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clipRule="evenodd"
                    />
                  </svg>
                </span>
              </summary>

              <ul class="mt-2 space-y-1 px-4">
                <li>
                  <a
                    href="#"
                    class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-700"
                  >
                    Banned Users
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-700"
                  >
                    Calendar
                  </a>
                </li>
              </ul>
            </details>
          </li>

         

          <li>
            <details class="group [&_summary::-webkit-details-marker]:hidden">
              <summary class="flex cursor-pointer items-center justify-between rounded-lg px-4 py-2 text-gray-500 hover:bg-gray-100 hover:text-gray-700">
                <span class="text-sm font-medium"> Account </span>

                <span class="shrink-0 transition duration-300 group-open:-rotate-180">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="h-5 w-5"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fill-rule="evenodd"
                      d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                      clip-rule="evenodd"
                    />
                  </svg>
                </span>
              </summary>

              <ul class="mt-2 space-y-1 px-4">
                <li>
                  <a
                    href="#"
                    class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-700"
                  >
                    Details
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    class="block rounded-lg px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-100 hover:text-gray-700"
                  >
                    Security
                  </a>
                </li>

                <li>
                  <form action="#">
                    <button
                      onClick={()=> logout()}
                      type="submit"
                      class="w-full rounded-lg px-4 py-2 text-sm font-medium text-gray-500 [text-align:_inherit] hover:bg-gray-100 hover:text-gray-700"
                    >
                      Logout
                    </button>
                  </form>
                </li>
              </ul>
            </details>
          </li>
        </ul>
      </div>

      <div class="sticky inset-x-0 bottom-0 border-t border-gray-100">
        <a
          href="#"
          class="flex items-center gap-2 bg-white p-4 hover:bg-gray-50"
        >
          <img
            alt=""
            src="https://static.wikia.nocookie.net/an-sharedcombined-crossovers/images/8/83/Profile_-_The_Young_Version_of_Michael_%22Goob%22_Yagoobian.png/revision/latest?cb=20230106072720"
            class="size-10 rounded-full object-cover"
          />

          <div>
            <p class="text-xs">
              <strong class="block font-medium">{user.name.first} {user.name.last}</strong>

              <span> {user.email} </span>
            </p>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Sidebar;
