import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";

const AddTaskForm = ({ onClose, projectId, users }) => {
  const dispatch = useDispatch();
  

  const [tags, setTags] = useState([]);
  const [error, setError] = useState("");

  // useEffect(() => {
  //   dispatch(fetchUsers());
  // }, [dispatch]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = new FormData(event.target);

    const newTask = {
      title: formData.get("title"),
      description: formData.get("description"),
      assignedTo: formData.get("assignedTo"),
      dueDate: formData.get("dueDate"),
      priority: formData.get("priority"),
      status: formData.get("status"),
      type: formData.get("type"),
      tags: tags,
    };

    try {
      const response = await axios.post(`http://localhost:3001/projects/${projectId}/tasks/create`, newTask);
      if (response.status === 201) {
        alert("Task added successfully!");
        onClose();
      } else {
        setError("Failed to add task. Please try again.");
      }
    } catch (error) {
      console.error("Error adding task:", error);
      setError("An error occurred while adding the task. Please try again.");
    }
  };

  const handleAddTag = (event) => {
    event.preventDefault();
    const tagName = event.target.tagName.value;
    const tagColor = event.target.tagColor.value;
    if (tagName && tagColor) {
      setTags([...tags, { name: tagName, color: tagColor }]);
      event.target.reset();
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50 p-10">
      <div className="bg-white shadow-md rounded-lg p-6 w-full max-w-4xl overflow-y-auto">
        <h2 className="text-2xl font-bold">Add Task</h2>
        <p className="mb-4 text-sm leading-6 text-gray-600">
          Add a Task to keep track of your progress
        </p>
        
        {error && <p className="text-red-500 mb-4">{error}</p>}
        
        <form onSubmit={handleSubmit}>
          <div className="grid gap-4 mb-4 sm:grid-cols-2 sm:gap-6 sm:mb-5">
            <div className="w-full">
              <label htmlFor="title" className="block mb-2 text-sm font-medium text-gray-900">
                Title
              </label>
              <input
                type="text"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                id="title"
                name="title"
                required
              />
            </div>

            {/* <div className="w-full">
              <label htmlFor="assignedTo" className="block mb-2 text-sm font-medium text-gray-900">
                Assigned To
              </label>
              <select
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                id="assignedTo"
                name="assignedTo"
                required
              >
                <option value="">Select a user</option>
                {users.map(user => (
                  <option key={user._id} value={user._id}>{user.name}</option>
                ))}
              </select>
            </div> */}

            <div className="col-span-full">
              <label htmlFor="description" className="block text-sm font-medium leading-6 text-gray-900">
                Description
              </label>
              <div className="mt-2">
                <textarea
                  id="description"
                  name="description"
                  rows="3"
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                ></textarea>
              </div>
            </div>

            <div className="w-full">
              <label htmlFor="dueDate" className="block mb-2 text-sm font-medium text-gray-900">
                Due Date
              </label>
              <input
                type="date"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                id="dueDate"
                name="dueDate"
              />
            </div>

            <div className="w-full">
              <label htmlFor="priority" className="block mb-2 text-sm font-medium text-gray-900">
                Priority
              </label>
              <select
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                id="priority"
                name="priority"
              >
                <option value="low">Low</option>
                <option value="medium">Medium</option>
                <option value="high">High</option>
              </select>
            </div>

            <div className="w-full">
              <label htmlFor="status" className="block mb-2 text-sm font-medium text-gray-900">
                Status
              </label>
              <select
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                id="status"
                name="status"
              >
                <option value="not started">Not Started</option>
                <option value="in progress">In Progress</option>
                <option value="in review">In Review</option>
                <option value="done">Done</option>
              </select>
            </div>

            <div className="w-full">
              <label htmlFor="type" className="block mb-2 text-sm font-medium text-gray-900">
                Type
              </label>
              <select
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                id="type"
                name="type"
              >
                <option value="Social Media Ads">Social Media Ads</option>
                <option value="Branding">Branding</option>
                <option value="Search Engine Marketing">Search Engine Marketing</option>
                <option value="Content Marketing">Content Marketing</option>
                <option value="Pay-Per-Click">Pay-Per-Click</option>
                <option value="Email Campaigns">Email Campaigns</option>
                <option value="Affiliate Marketing">Affiliate Marketing</option>
                <option value="Web Development">Web Development</option>
                <option value="Market Analysis">Market Analysis</option>
                <option value="Product Marketing">Product Marketing</option>
                <option value="Other">Other</option>
              </select>
            </div>
          </div>

          <div className="mb-4">
            <h3 className="text-lg font-medium mb-2">Tags</h3>
            <form onSubmit={handleAddTag} className="flex gap-2 mb-2">
              <input
                type="text"
                name="tagName"
                placeholder="Tag name"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 p-2"
              />
              <input
                type="color"
                name="tagColor"
                className="h-10 w-10"
              />
              <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md">Add Tag</button>
            </form>
            <div className="flex flex-wrap gap-2">
              {tags.map((tag, index) => (
                <span key={index} className="px-2 py-1 rounded-full text-sm" style={{backgroundColor: tag.color, color: 'white'}}>
                  {tag.name}
                </span>
              ))}
            </div>
          </div>

          <div className="flex justify-end mt-4">
            <button
              type="button"
              className="bg-gray-300 text-gray-800 px-4 py-2 rounded-md mr-2 hover:bg-white"
              onClick={onClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
            >
              Add Task
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddTaskForm;