import React, { useState } from "react";
import { Draggable } from "react-beautiful-dnd";
import { ChevronDown, ChevronUp, Clock, Tag } from "lucide-react";

const TaskCard = ({ task, index }) => {
  const [isSubtasksOpen, setIsSubtasksOpen] = useState(false);

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  return (
    <Draggable draggableId={task._id.toString()} index={index}>
      {(provided, snapshot) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="bg-white p-4 rounded-lg shadow-sm mb-4"
        >
          <h3 className="font-semibold mb-2">{task.title}</h3>
          <p className="text-sm text-gray-600 mb-2">{task.description}</p>
          
          <div className="flex justify-between items-center mb-2">
            <span
              className={`px-2 py-1 rounded-full text-xs ${
                task.priority === "high"
                  ? "bg-red-200 text-red-800"
                  : task.priority === "medium"
                  ? "bg-yellow-200 text-yellow-800"
                  : "bg-green-200 text-green-800"
              }`}
            >
              {task.priority?.toUpperCase()}
            </span>
            {task.dueDate && (
              <span className="text-xs text-gray-500 flex items-center">
                <Clock size={12} className="mr-1" />
                {formatDate(task.dueDate)}
              </span>
            )}
          </div>

          {task.tags && task.tags.length > 0 && (
            <div className="flex flex-wrap gap-2 mb-2">
              <Tag size={12} className="text-gray-500 mt-1.5" />
              {task.tags.map((tag, tagIndex) => (
                
              <span
                key={tagIndex}
                className={`px-2 py-1 rounded-full text-xs ${tag.color}`}
              >
                
                {tag.name}
              </span>
            ))}
            </div>
          )}

          {task.subtasks && task.subtasks.length > 0 && (
            <div className="mt-2">
              <button
                onClick={() => setIsSubtasksOpen(!isSubtasksOpen)}
                className="flex items-center text-sm text-blue-600 hover:text-blue-800"
              >
                {isSubtasksOpen ? (
                  <ChevronUp size={16} className="mr-1" />
                ) : (
                  <ChevronDown size={16} className="mr-1" />
                )}
                Subtasks ({task.subtasks.length})
              </button>
              {isSubtasksOpen && (
                <ul className="mt-2 space-y-1">
                  {task.subtasks.map((subtask) => (
                    <li key={subtask._id} className="text-sm text-gray-600 flex items-center">
                      <span className="w-2 h-2 rounded-full bg-gray-400 mr-2"></span>
                      {subtask.title}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          )}
        </div>
      )}
    </Draggable>
  );
};

export default TaskCard;