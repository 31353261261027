import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { sendToVercelAnalytics } from "./vitals";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import LoginPage from "./pages/Auth/LoginPage";
import Dashboard from "./pages/Client/dashboard";
import Home from "./pages/Admin/home";
import Profile from "./pages/Client/profile";
import { configureStore } from "@reduxjs/toolkit";
import { Provider } from "react-redux";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";
import { PersistGate } from "redux-persist/integration/react";

import WebsiteLayout from "./layouts/WebsiteLayout"


import Client from "./pages/Client";
import Admin from "./pages/Admin";
import Clients from "./pages/Admin/clients"
import Projects from "./pages/Admin/projects";
import Campaigns from "./pages/Admin/campaigns"
import ErrorPage from "./common/error-page";
import { combineReducers } from "@reduxjs/toolkit";
import companiesReducer from "./features/companies/companiesSlice"
import authReducer from "./features/auth/authSlice"
import Invoices from "./pages/Admin/invoices";
import Tasks from "./pages/Admin/tasks";
import HomePage from "./pages/Website/pages/HomePage";
import AboutPage from "./pages/Website/pages/AboutPage";
import PortfolioPage from "./pages/Website/pages/PortfolioPage";
import ContactPage from "./pages/Website/pages/ContactPage";
import FAQPage from "./pages/Website/pages/FAQPage";
import SolutionsPage from "./pages/Website/pages/SolutionsPage";
import ServicesPage from "./pages/Website/pages/ServicesPage";
import TermsOfService from "./pages/Website/pages/TermsAndConditions";
import PrivacyPolicy from "./pages/Website/pages/PrivacyPolicy";


const router = createBrowserRouter([

  {
    path: "/",
    element: <WebsiteLayout />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <HomePage />
      },
      {
        path: "about",
        element: <AboutPage />
      },
      {
        path: "services",
        element: <ServicesPage />
      },
      {
        path: "solutions",
        element: <SolutionsPage />
      },
      {
        path: "portfolio",
        element: <PortfolioPage />
      },
      {
        path: "contact",
        element: <ContactPage />
      },
      {
        path: "faqs",
        element: <FAQPage />
      },
      {
        path: "terms-and-conditions",
        element: <TermsOfService />
      },
      {
        path: "privacy-policy",
        element: <PrivacyPolicy />
      },
      
      // Add other website routes here
    ],
  },
  {
    path: "login",
    element: <LoginPage />,
  },
  
 
  {
    path: "/app",
    element: <App />,
    children: [
      {
        path: "admin",
        element: <Admin />,
        children: [
          {
            path: "home",
            element: <Home text={"dashboard"} />,
          },
          {
            path: "clients",
            element: <Clients text={"manage-accounts"} />,
          },
          {
            path: "projects",
            element: <Projects text={"manage-events"} />,
          },
          {
            path: "campaigns",
            element: <Campaigns />,
          },
          {
            path: "tasks",
            element: <Tasks/>
          },
          {
            path: "invoices",
            element: <Invoices />,
          },
        ],
      },
      {
        path: "client",
        element: <Client />,
        children: [
          {
            path: "dashboard",
            element: <Dashboard text={"dashboard"} />,
          },
          {
            path: "team",
            element: <Dashboard text={"manage-accounts"} />,
          },
          {
            path: "manage-events",
            element: <Dashboard text={"manage-events"} />,
          },
          {
            path: "profile",
            element: <Profile />,
          },
        ],
      },
      

    ]
  },

  
  
]);

const persistConfig = { 
  key: "root", 
  storage, 
  version: 1 
};

const rootReducer = combineReducers({
  auth: authReducer,
  companies: companiesReducer,
});




const persistedReducer = persistReducer(persistConfig, rootReducer);


const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

ReactDOM.createRoot(document.getElementById("root")).render(
  
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistStore(store)}>
      <RouterProvider router={router} />
      </PersistGate>
    </Provider>
 
);

reportWebVitals(sendToVercelAnalytics);
