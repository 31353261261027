import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import AssignmentIcon from '@mui/icons-material/Assignment';
import PersonIcon from '@mui/icons-material/Person';
import PinIcon from '@mui/icons-material/Pin';
import TaskIcon from '@mui/icons-material/Task';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';

export const routes = [
    {
        id: 0,
        icon: <HomeOutlinedIcon sx={{ color: "#112D4E"}}/>,
        text: "Home",
        link: "home"
    },
    {
        id: 1,
        icon: <AssignmentIcon sx={{ color: "#112D4E"}}/>,
        text: "Clients | Companies",
        link: "clients"
    },
    {
        id: 2,
        icon: <TaskIcon sx={{ color: "#112D4E"}}/>,
        text: "Manage Projects",
        link: "projects"
    }, 
    {
        id: 3,
        icon: <PersonIcon sx={{ color: "#112D4E"}}/>,
        text: "Manage Campaigns",
        link: "campaigns"
    },
    {
        id: 4,
        icon: <PersonIcon sx={{ color: "#112D4E"}}/>,
        text: "Manage Tasks",
        link: "tasks"
    },
    {
        id: 5,
        icon: <ReceiptOutlinedIcon sx={{ color: "#112D4E"}}/>,
        text: "Invoices",
        link: "invoices"
    },
    

]