import React from "react";
import WebsiteNavbar from "../pages/Website/components/WebsiteNavbar";
import WebsiteFooter from "../pages/Website/components/WebsiteFooter";
import { Outlet } from "react-router-dom";

const WebsiteLayout = () => {
    return (
      <div className="min-h-screen">
        <header>
          <WebsiteNavbar />
        </header>
        <main>
          <Outlet />
        </main>
        <footer>
          <WebsiteFooter />
        </footer>
      </div>
    );
  };
  
  export default WebsiteLayout;