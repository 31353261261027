import React from 'react';
import logo from '../../../assets/ouitrend.png';
import { Facebook, Instagram, Twitter, Github, Dribbble, Mail, Phone, MapPin } from 'lucide-react';

const WebsiteFooter = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className="bg-gradient-to-b from-white to-gray-50">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 pt-16 pb-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-12 gap-8 lg:gap-12">
          {/* Company Info */}
          <div className="lg:col-span-4">
            <div className="flex items-center mb-6">
              <img src={logo} className="h-16 w-auto" alt="OuiTrend Logo" />
             
            </div>
            <p className="text-gray-600 mb-6">
              Transforming businesses through AI-powered marketing and innovative software solutions.
            </p>
          </div>

          {/* Quick Links */}
          <div className="lg:col-span-2">
            <h3 className="text-lg font-semibold text-[#223362] mb-6">Resources</h3>
            <ul className="space-y-4">
              <li>
                <a href="https://ouitrend.com/faqs" className="text-gray-600 hover:text-[#41B07D] transition-colors duration-200">
                  FAQs
                </a>
              </li>
              <li>
                <a href="https://ouitrend.com/login" className="text-gray-600 hover:text-[#41B07D] transition-colors duration-200">
                  Login
                </a>
              </li>
            </ul>
          </div>

          {/* Follow Us */}
          <div className="lg:col-span-2">
            <h3 className="text-lg font-semibold text-[#223362] mb-6">Follow Us</h3>
            <ul className="space-y-4">
              <li>
                <a 
                  href="https://www.instagram.com/ouitrendmarketing" 
                  className="text-gray-600 hover:text-[#41B07D] transition-colors duration-200 flex items-center"
                >
                  <Instagram className="w-5 h-5 mr-2" />
                  Instagram
                </a>
              </li>
            </ul>
          </div>

          {/* Legal */}
          <div className="lg:col-span-2">
            <h3 className="text-lg font-semibold text-[#223362] mb-6">Legal</h3>
            <ul className="space-y-4">
              <li>
                <a href="/privacy-policy" className="text-gray-600 hover:text-[#41B07D] transition-colors duration-200">
                  Privacy Policy
                </a>
              </li>
              <li>
                <a href="/terms-and-conditions" className="text-gray-600 hover:text-[#41B07D] transition-colors duration-200">
                  Terms & Conditions
                </a>
              </li>
            </ul>
          </div>

          {/* Contact Info */}
          <div className="lg:col-span-2">
            <h3 className="text-lg font-semibold text-[#223362] mb-6">Contact Us</h3>
            <div className="space-y-4">
              <a href="mailto:contact@ouitrend.com" className="flex items-center text-gray-600 hover:text-[#41B07D] transition-colors duration-200">
                <Mail className="w-5 h-5 mr-3" />
                <span>contact@ouitrend.com</span>
              </a>
              {/* <a href="tel:+1234567890" className="flex items-center text-gray-600 hover:text-[#41B07D] transition-colors duration-200">
                <Phone className="w-5 h-5 mr-3" />
                <span>+1 (234) 567-8900</span>
              </a> */}
            </div>
          </div>
        </div>

        <hr className="my-8 border-gray-200" />

        {/* Bottom Section */}
        <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
          <p className="text-gray-600">
            © {currentYear} <a href="https://ouitrend.com" className="hover:text-[#41B07D]">OuiTrend™</a>. All Rights Reserved.
          </p>

          {/* Social Icons */}
          <div className="flex space-x-6">
            <a href="#" className="text-gray-400 hover:text-[#41B07D] transition-colors duration-200">
              <Facebook className="w-5 h-5" />
            </a>
            <a href="https://www.instagram.com/ouitrendmarketing/" className="text-gray-400 hover:text-[#41B07D] transition-colors duration-200">
              <Instagram className="w-5 h-5" />
            </a>
            <a href="#" className="text-gray-400 hover:text-[#41B07D] transition-colors duration-200">
              <Twitter className="w-5 h-5" />
            </a>
            <a href="#" className="text-gray-400 hover:text-[#41B07D] transition-colors duration-200">
              <Github className="w-5 h-5" />
            </a>
            <a href="#" className="text-gray-400 hover:text-[#41B07D] transition-colors duration-200">
              <Dribbble className="w-5 h-5" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default WebsiteFooter;