import React, { useState } from 'react';
import { ChevronDown, ChevronUp, ArrowRight } from 'lucide-react';

const FAQPage = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const faqs = [
    {
      category: "Services & Solutions",
      questions: [
        {
          question: "How do you determine which solutions are right for my business?",
          answer: "We begin with a comprehensive consultation to understand your business goals, challenges, and current processes. Our team then analyzes your needs to develop a customized solution proposal that aligns with your objectives and growth plans."
        },
        {
          question: "Do you provide pricing upfront?",
          answer: "We believe in providing customized solutions that deliver real value. Rather than offering one-size-fits-all pricing, we conduct thorough consultations to understand your needs and develop tailored proposals. This ensures you receive solutions that align with your budget while meeting your specific business requirements."
        },
        {
          question: "How does your scalability model work?",
          answer: "Our services are designed to grow with your business. We start with entry-level solutions and scale up based on performance and results. As we demonstrate value and ROI, we can expand services and capabilities to match your growing needs, ensuring you only pay for what delivers measurable results."
        }
      ]
    },
    {
      category: "Project Timeline & Delivery",
      questions: [
        {
          question: "How long does a typical project take to implement?",
          answer: "Project timelines vary based on scope and complexity. During our consultation, we provide detailed project schedules with clear milestones and deliverables. We believe in transparent communication and will keep you updated throughout the implementation process."
        },
        {
          question: "How do you handle project milestones and deliverables?",
          answer: "Each project includes a comprehensive delivery schedule with defined milestones. We provide regular updates and maintain clear communication channels to ensure you're always informed of progress and any adjustments needed."
        },
        {
          question: "Can projects be expedited if needed?",
          answer: "While we prioritize quality and thoroughness, we understand that sometimes businesses need accelerated timelines. During consultation, we can discuss various implementation approaches and find the best balance of speed and quality for your needs."
        }
      ]
    },
    {
      category: "Technology & Implementation",
      questions: [
        {
          question: "What technologies do you use in your solutions?",
          answer: "We utilize cutting-edge technologies including AI, cloud services, and modern development frameworks. The specific technology stack is chosen based on your project requirements, ensuring the most efficient and effective solution for your needs."
        },
        {
          question: "How do you ensure solution quality and reliability?",
          answer: "Our development process includes rigorous testing, quality assurance, and performance monitoring. We implement industry best practices and maintain high standards throughout development and deployment."
        }
      ]
    },
    {
      category: "Support & Maintenance",
      questions: [
        {
          question: "What kind of support do you provide after implementation?",
          answer: "We provide ongoing support and maintenance to ensure your solutions continue performing optimally. Support levels can be customized based on your needs, from basic maintenance to comprehensive managed services."
        },
        {
          question: "How do you handle updates and improvements?",
          answer: "Regular updates and improvements are part of our service model. We continuously monitor performance and implement updates to enhance functionality, security, and efficiency based on your business needs and industry trends."
        }
      ]
    }
  ];

  return (
    <div className="min-h-screen pt-20">
      {/* Hero Section */}
      <section className="relative bg-gradient-to-br from-[#223362] to-[#41B07D] text-white py-20">
        <div className="absolute inset-0 bg-black/20"></div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h1 className="text-4xl md:text-5xl font-bold mb-6">
            Frequently Asked Questions
          </h1>
          <p className="text-xl text-gray-100 max-w-2xl mx-auto">
            Learn more about our approach to delivering customized solutions that drive 
            business growth and efficiency.
          </p>
        </div>
      </section>

      {/* FAQ Content */}
      <section className="py-16 bg-gray-50">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
          {faqs.map((category, categoryIndex) => (
            <div key={categoryIndex} className="mb-12">
              <h2 className="text-2xl font-bold text-[#223362] mb-6">
                {category.category}
              </h2>
              <div className="space-y-4">
                {category.questions.map((faq, index) => {
                  const actualIndex = `${categoryIndex}-${index}`;
                  const isOpen = openIndex === actualIndex;
                  
                  return (
                    <div 
                      key={index}
                      className="bg-white rounded-lg shadow-sm border border-gray-100 overflow-hidden"
                    >
                      <button
                        className="w-full px-6 py-4 text-left flex justify-between items-center focus:outline-none"
                        onClick={() => setOpenIndex(isOpen ? null : actualIndex)}
                      >
                        <span className="text-lg font-medium text-[#223362]">
                          {faq.question}
                        </span>
                        {isOpen ? (
                          <ChevronUp className="w-5 h-5 text-[#41B07D]" />
                        ) : (
                          <ChevronDown className="w-5 h-5 text-[#41B07D]" />
                        )}
                      </button>
                      
                      {isOpen && (
                        <div className="px-6 pb-4">
                          <p className="text-gray-600">{faq.answer}</p>
                        </div>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
          ))}
        </div>

        {/* CTA Section */}
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 mt-12">
          <div className="bg-[#223362] rounded-xl p-8 text-center">
            <h2 className="text-2xl font-bold text-white mb-4">
              Have More Questions?
            </h2>
            <p className="text-gray-300 mb-6">
              Schedule a consultation to discuss your specific needs and how we can help transform your business.
            </p>
            <a
              href="/contact"
              className="inline-flex items-center px-6 py-3 bg-[#41B07D] text-white rounded-lg hover:bg-[#41B07D]/90 transition-all duration-300"
            >
              Schedule a Consultation
              <ArrowRight className="ml-2 w-5 h-5" />
            </a>
          </div>
        </div>
      </section>
    </div>
  );
};

export default FAQPage;