import React from 'react';
import { ArrowRight, MessageSquare, Zap, Shield } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const CTASection = () => {
  const navigate = useNavigate();

  const handleContactClick = () => {
    navigate('/contact');
  };

  const benefits = [
    {
      icon: <Zap className="w-6 h-6" />,
      text: "AI-Powered Solutions"
    },
    {
      icon: <Shield className="w-6 h-6" />,
      text: "Enterprise-Grade Security"
    },
    {
      icon: <MessageSquare className="w-6 h-6" />,
      text: "24/7 Expert Support"
    }
  ];

  return (
    <section className="relative py-20 overflow-hidden">
      {/* Background with gradient overlay */}
      <div className="absolute inset-0 bg-gradient-to-br from-[#223362] to-[#41B07D] opacity-90" />
      
      {/* Decorative circles */}
      <div className="absolute top-0 left-0 w-72 h-72 bg-white/10 rounded-full -translate-x-1/2 -translate-y-1/2 blur-3xl" />
      <div className="absolute bottom-0 right-0 w-72 h-72 bg-white/10 rounded-full translate-x-1/2 translate-y-1/2 blur-3xl" />

      <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center">
          <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">
            Ready to Transform Your Digital Presence?
          </h2>
          <p className="text-xl text-gray-100 mb-8 max-w-2xl mx-auto">
            Join hundreds of businesses leveraging our AI-powered marketing and custom software solutions.
          </p>

          {/* Benefits */}
          <div className="flex flex-wrap justify-center gap-8 mb-12">
            {benefits.map((benefit, index) => (
              <div 
                key={index}
                className="flex items-center text-white gap-2"
              >
                <div className="text-[#41B07D]">
                  {benefit.icon}
                </div>
                <span>{benefit.text}</span>
              </div>
            ))}
          </div>

          {/* CTA Buttons */}
          <div className="flex flex-col sm:flex-row justify-center gap-4 max-w-md mx-auto">
            <button
              onClick={handleContactClick}
              className="px-8 py-4 bg-white text-[#223362] rounded-lg hover:bg-gray-100 transition-all duration-300 shadow-lg hover:shadow-xl flex items-center justify-center font-medium group"
            >
              Get Started Now
              <ArrowRight className="ml-2 w-5 h-5 group-hover:translate-x-1 transition-transform" />
            </button>
            {/* <button
              onClick={() => window.open('tel:+1234567890')}
              className="px-8 py-4 border-2 border-white text-white rounded-lg hover:bg-white hover:text-[#223362] transition-all duration-300 font-medium"
            >
              Call Us Today
            </button> */}
          </div>

          {/* Social Proof */}
          
        </div>
      </div>
    </section>
  );
};

export default CTASection;