import React from 'react'
import Layout from '../../../common/layout'

const Invoices = () => {
  return (
    <Layout>
        <header>
        <div className="mb-5">
          <div className="sm:flex sm:items-center sm:justify-between">
            <div className="text-center sm:text-left">
              <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">
                Invoices
              </h1>
              <p className="mt-1.5 text-sm text-gray-500">
                Manage Client | Companies' Invoices for OuiTrend
              </p>
            </div>
            <div className="mt-4 flex flex-col gap-4 sm:mt-0 sm:flex-row sm:items-center">
              <a
                className="inline-flex items-center justify-center gap-1.5 rounded-lg border border-gray-200 px-5 py-3 text-gray-500 transition hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring"
                href="https://www.ouitrend.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="text-sm font-medium">View Website</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                  />
                </svg>
              </a>

              <button
                className="block rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring"
                type="button"
                onClick={null}
              >
                Add Project
              </button>
            </div>
          </div>
        </div>
      </header>


      <div className='w-100 flex justify-center text-xl font-bold text-oui-four mt-20'>
       <div className='p-20 rounded-lg bg-white w-max shadow-md'>
       <p>
          Will be available, next update ✅
        </p>

       </div>
      </div>
        

    </Layout>
  )
}

export default Invoices