import React, { useState, useEffect } from "react";
import { Menu, X, ChevronDown } from "lucide-react";
import logo from "../../../assets/ouitrend.png";
import { useNavigate } from "react-router-dom";

const WebsiteNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const [activeDropdown, setActiveDropdown] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 20);
    };
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const solutions = [
    { name: "AI Marketing", description: "Data-driven marketing strategies" },
    { name: "Custom Software", description: "Tailored software solutions" },
    { name: "Digital Analytics", description: "Advanced data insights" },
    { name: "Automation", description: "Workflow optimization" },
  ];

  const services = [
    {
      name: "Social Media Management",
      description: "AI-powered social strategies",
    },
    { name: "Content Creation", description: "Engaging content at scale" },
    { name: "SEO Optimization", description: "Data-driven SEO" },
    { name: "Web Development", description: "Custom web solutions" },
  ];

  return (
    <nav
      className={`fixed w-full z-50 transition-all duration-300 ${
        isScrolled ? "bg-white shadow-lg" : "bg-white/95 backdrop-blur-sm"
      }`}
    >
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-20">
          {/* Logo */}
          <a href="https://ouitrend.com" className="flex-shrink-0">
            <img
              src={logo}
              className="h-48 w-auto object-contain"
              alt="OuiTrend Logo"
            />
          </a>

          {/* Desktop Navigation */}
          <div className="hidden lg:flex items-center space-x-8">
            <a
              href="/"
              className="text-[#223362] hover:text-[#41B07D] transition-colors duration-200 font-medium"
            >
              Home
            </a>

            {/* Solutions Dropdown */}
            <div className="relative group">
              <button
                onClick={() => navigate("/solutions")}
                onMouseEnter={() => setActiveDropdown("solutions")}
                className="flex items-center text-[#223362] hover:text-[#41B07D] transition-colors duration-200 font-medium"
              >
                Solutions <ChevronDown className="ml-1 h-4 w-4" />
              </button>
              {activeDropdown === "solutions" && (
                <div
                  onMouseLeave={() => setActiveDropdown(null)}
                  className="absolute left-0 mt-2 w-64 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 p-2"
                >
                  {solutions.map((item) => (
                    <button
                      key={item.name}
                      onClick={() => {
                        navigate("/solutions");
                        setActiveDropdown(null);
                      }}
                      className="w-full text-left block rounded-md px-4 py-2 hover:bg-gray-50"
                    >
                      <p className="text-[#223362] font-medium">{item.name}</p>
                      <p className="text-sm text-gray-500">
                        {item.description}
                      </p>
                    </button>
                  ))}
                </div>
              )}
            </div>

            {/* Services Dropdown */}
            <div className="relative group">
              <button
                onClick={() => navigate("/services")}
                onMouseEnter={() => setActiveDropdown("services")}
                className="flex items-center text-[#223362] hover:text-[#41B07D] transition-colors duration-200 font-medium"
              >
                Services <ChevronDown className="ml-1 h-4 w-4" />
              </button>
              {activeDropdown === "services" && (
                <div
                  onMouseLeave={() => setActiveDropdown(null)}
                  className="absolute left-0 mt-2 w-64 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5 p-2"
                >
                  {services.map((item) => (
                    <button
                      key={item.name}
                      onClick={() => {
                        navigate("/services");
                        setActiveDropdown(null);
                      }}
                      className="w-full text-left block rounded-md px-4 py-2 hover:bg-gray-50"
                    >
                      <p className="text-[#223362] font-medium">{item.title}</p>
                      <p className="text-sm text-gray-500">
                        {item.description}
                      </p>
                    </button>
                  ))}
                </div>
              )}
            </div>
            <a
              href="/portfolio"
              className="text-[#223362] hover:text-[#41B07D] transition-colors duration-200 font-medium"
            >
              Portfolio
            </a>

            <a
              href="/about"
              className="text-[#223362] hover:text-[#41B07D] transition-colors duration-200 font-medium"
            >
              About
            </a>

            <a
              href="/faqs"
              className="text-[#223362] hover:text-[#41B07D] transition-colors duration-200 font-medium"
            >
              FAQs
            </a>

            <a
              href="/contact"
              className="text-[#223362] hover:text-[#41B07D] transition-colors duration-200 font-medium"
            >
              Contact
            </a>

            <a
              href="/login"
              className="px-4 py-2 bg-[#41B07D] text-white rounded-md hover:bg-[#41B07D]/90 transition-colors duration-200"
            >
              Sign In
            </a>
          </div>

          {/* Mobile menu button */}
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="lg:hidden p-2 rounded-lg hover:bg-gray-100 focus:outline-none"
          >
            {isOpen ? (
              <X className="h-6 w-6 text-[#223362]" />
            ) : (
              <Menu className="h-6 w-6 text-[#223362]" />
            )}
          </button>
        </div>

        {/* Mobile menu */}
        <div className={`lg:hidden ${isOpen ? "block" : "hidden"} pb-6`}>
          <div className="flex flex-col space-y-4">
            <a
              href="/"
              className="text-[#223362] hover:text-[#41B07D] font-medium px-4 py-2"
            >
              Home
            </a>

            {/* Mobile Solutions */}
            <div className="px-4 py-2">
              <p className="font-medium text-[#223362] mb-2">Solutions</p>
              {solutions.map((item) => (
                    <button
                      key={item.name}
                      onClick={() => {
                        navigate("/solutions");
                        setActiveDropdown(null);
                      }}
                      className="w-full text-left block rounded-md px-4 py-2 hover:bg-gray-50"
                    >
                      <p className="text-[#223362] font-medium">{item.name}</p>
                      <p className="text-sm text-gray-500">
                        {item.description}
                      </p>
                    </button>
                  ))}
            </div>

            {/* Mobile Services */}
            <div className="px-4 py-2">
              <p className="font-medium text-[#223362] mb-2">Services</p>
              {services.map((item) => (
                    <button
                      key={item.name}
                      onClick={() => {
                        navigate("/services");
                        setActiveDropdown(null);
                      }}
                      className="w-full text-left block rounded-md px-4 py-2 hover:bg-gray-50"
                    >
                      <p className="text-[#223362] font-medium">{item.title}</p>
                      <p className="text-sm text-gray-500">
                        {item.description}
                      </p>
                    </button>
                  ))}
            </div>

            <a
              href="#about"
              className="text-[#223362] hover:text-[#41B07D] font-medium px-4 py-2"
            >
              About
            </a>
            <a
              href="#contact"
              className="text-[#223362] hover:text-[#41B07D] font-medium px-4 py-2"
            >
              Contact
            </a>
            <div className="px-4">
              <a
                href="/login"
                className="block text-center px-4 py-2 bg-[#41B07D] text-white rounded-md hover:bg-[#41B07D]/90 transition-colors duration-200"
              >
                Get Started
              </a>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default WebsiteNavbar;
