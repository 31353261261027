// PrivacyPolicy.jsx
import React from 'react';
import { Shield, Lock, Eye, FileText } from 'lucide-react';

const PrivacyPolicy = () => {
  const sections = [
    {
      icon: <Shield className="w-6 h-6" />,
      title: "Information Collection",
      content: [
        "Personal information (name, email, phone number)",
        "Business information",
        "Technical data (IP address, browser type)",
        "Usage data and analytics",
        "Communication records"
      ]
    },
    {
      icon: <Eye className="w-6 h-6" />,
      title: "Information Usage",
      content: [
        "Service delivery and improvement",
        "Communication about services",
        "Marketing (with consent)",
        "Legal obligations",
        "Analytics and research"
      ]
    },
    {
      icon: <Lock className="w-6 h-6" />,
      title: "Data Protection",
      content: [
        "Industry-standard security measures",
        "Encrypted data transmission",
        "Regular security audits",
        "Access controls",
        "Data backup and recovery"
      ]
    },
    {
      icon: <FileText className="w-6 h-6" />,
      title: "Your Rights",
      content: [
        "Access your personal data",
        "Request data correction",
        "Withdraw consent",
        "Data portability",
        "Right to be forgotten"
      ]
    }
  ];

  return (
    <div className="min-h-screen pt-20">
      {/* Hero Section */}
      <section className="relative bg-gradient-to-br from-[#223362] to-[#41B07D] text-white py-16">
        <div className="absolute inset-0 bg-black/20"></div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h1 className="text-4xl font-bold mb-4">Privacy Policy</h1>
          <p className="text-xl text-gray-100 max-w-2xl mx-auto">
            Your privacy is important to us. This policy outlines how we collect, use, 
            and protect your information.
          </p>
        </div>
      </section>

      {/* Last Updated */}
      <div className="bg-gray-50 py-4">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <p className="text-gray-600">Last Updated: February 12, 2025</p>
        </div>
      </div>

      {/* Main Content */}
      <section className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="prose max-w-none">
            <p className="text-gray-600 mb-12">
              At OuiTrend, we take your privacy seriously. This Privacy Policy explains how we collect, 
              use, disclose, and safeguard your information when you use our services.
            </p>

            {sections.map((section, index) => (
              <div key={index} className="mb-12">
                <div className="flex items-center mb-6">
                  <div className="w-12 h-12 bg-[#41B07D]/10 rounded-lg flex items-center justify-center text-[#41B07D] mr-4">
                    {section.icon}
                  </div>
                  <h2 className="text-2xl font-bold text-[#223362]">{section.title}</h2>
                </div>
                <ul className="list-none space-y-3 pl-16">
                  {section.content.map((item, i) => (
                    <li key={i} className="text-gray-600 flex items-center">
                      <div className="w-1.5 h-1.5 rounded-full bg-[#41B07D] mr-2"></div>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}

            <div className="mt-12 p-6 bg-gray-50 rounded-lg">
              <h2 className="text-2xl font-bold text-[#223362] mb-4">Contact Us</h2>
              <p className="text-gray-600">
                If you have any questions about our Privacy Policy, please contact us at{' '}
                <a href="mailto:privacy@ouitrend.com" className="text-[#41B07D] hover:underline">
                  privacy@ouitrend.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default PrivacyPolicy;