import React, { useState, useEffect } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import Layout from "../../../common/layout";
import axios from "axios";
import TaskColumn from "./components/taskColumn";
import { useSelector } from "react-redux";
import { baseUrl } from "../../../api";
import DropDown from "./components/dropDown";
import AddTaskForm from "./components/addTaskForm";


const Tasks = () => {
    const [columns, setColumns] = useState({
        todo: { id: "todo", title: "To Do", tasks: [] },
        inProgress: { id: "inProgress", title: "In Progress", tasks: [] },
        inReview: { id: "inReview", title: "In Review", tasks: [] },
        done: { id: "done", title: "Done", tasks: [] },
      });
    
      const [isAddTaskFormOpen, setIsAddTaskFormOpen] = useState(false);
    
      const [selectedCompany, setSelectedCompany] = useState(null);
      const [selectedProject, setSelectedProject] = useState(null);
      const [projects, setProjects] = useState([]);
      const [error, setError] = useState("");
    
      const { list: companies, status } = useSelector((state) => state.companies);
    
      useEffect(() => {
        if (selectedCompany) {
          fetchProjects(selectedCompany);
        }
      }, [selectedCompany]);
    
      useEffect(() => {
        if (selectedProject) {
          handleFetchTasks(selectedProject);
        }
      }, [selectedProject]);
    
      const fetchProjects = async (companyId) => {
        try {
          const response = await axios.get(
            `${baseUrl}companies/${companyId}/projects`
          );
          setProjects(response.data);
        } catch (e) {
          console.error("Error fetching projects:", e);
          setError("Failed to fetch projects. Please try again.");
        }
      };
    
      const handleFetchTasks = async (projectId) => {
        try {
          const response = await axios.get(
            `${baseUrl}projects/${projectId}/tasks/all`
          );
          const tasks = response.data;
          const newColumns = {
            todo: { ...columns.todo, tasks: [] },
            inProgress: { ...columns.inProgress, tasks: [] },
            inReview: { ...columns.inReview, tasks: [] },
            done: { ...columns.done, tasks: [] },
          };
    
          tasks.forEach((task) => {
            if (!task._id) {
              task._id = `task-${Math.random().toString(36).substr(2, 9)}`;
            }
    
            const status = task.status
              ? task.status.toLowerCase().replace(/\s+/g, "")
              : "";
    
            if (status.includes("todo") || status.includes("notstarted")) {
              newColumns.todo.tasks.push(task);
            } else if (status.includes("inprogress") || status.includes("doing")) {
              newColumns.inProgress.tasks.push(task);
            } else if (status.includes("review") || status.includes("testing")) {
              newColumns.inReview.tasks.push(task);
            } else if (status.includes("done") || status.includes("completed")) {
              newColumns.done.tasks.push(task);
            } else {
              console.warn(`Unknown status: ${status} for task: ${task._id}`);
              newColumns.todo.tasks.push(task);
            }
          });
    
          setColumns(newColumns);
        } catch (e) {
          console.error("Error fetching tasks:", e);
          setError("Failed to fetch tasks. Please try again.");
        }
      };
    
      const updateTaskStatus = async (taskId, newStatus) => {
        try {
          const response = await axios.put(`${baseUrl}projects/tasks/${taskId}`, {
            status: mapStatusToSchemaEnum(newStatus),
          });
          console.log("Task updated successfully:", response.data);
          return response.data;
        } catch (e) {
          console.error("Error updating task status:", e.message);
          throw e;
        }
      };
    
      const mapStatusToSchemaEnum = (status) => {
        const statusMap = {
          todo: "not started",
          inProgress: "in progress",
          inReview: "in review",
          done: "done",
        };
        return statusMap[status] || "not started";
      };
    
      const onDragEnd = (result) => {
        console.log("Drag ended:", result);
        const { source, destination } = result;
    
        if (!destination) {
          return;
        }
    
        if (
          source.droppableId === destination.droppableId &&
          source.index === destination.index
        ) {
          return;
        }
    
        const sourceColumn = columns[source.droppableId];
        const destColumn = columns[destination.droppableId];
    
        if (!sourceColumn || !destColumn) {
          console.error(
            "Invalid source or destination column:",
            source.droppableId,
            destination.droppableId
          );
          return;
        }
    
        const sourceTasks = [...sourceColumn.tasks];
        const destTasks =
          source.droppableId === destination.droppableId
            ? sourceTasks
            : [...destColumn.tasks];
    
        const [removedTask] = sourceTasks.splice(source.index, 1);
        destTasks.splice(destination.index, 0, removedTask);
    
        const newColumns = {
          ...columns,
          [source.droppableId]: {
            ...sourceColumn,
            tasks: sourceTasks,
          },
          [destination.droppableId]: {
            ...destColumn,
            tasks: destTasks,
          },
        };
    
        setColumns(newColumns);
    
        updateTaskStatus(removedTask._id, destination.droppableId);
      };
    
      const handleAddTask = () => {
        setIsAddTaskFormOpen(true);
      };
    
      const handleCloseForm = () => {
        setIsAddTaskFormOpen(false);
      };
    
      return (
        <Layout>
          <header>
            <div className="mb-5">
              <div className="sm:flex sm:items-center sm:justify-between">
                <div className="text-center sm:text-left">
                  <h1 className="text-2xl font-bold text-gray-900 sm:text-3xl">
                    Manage Projects
                  </h1>
                  <p className="mt-1.5 text-sm text-gray-500">
                    Manage Client | Companies' Projects
                  </p>
                </div>
                <div className="mt-4 flex flex-col gap-4 sm:mt-0 sm:flex-row sm:items-center">
                  <a
                    className="inline-flex items-center justify-center gap-1.5 rounded-lg border border-gray-200 px-5 py-3 text-gray-500 transition hover:bg-gray-50 hover:text-gray-700 focus:outline-none focus:ring"
                    href="https://www.ouitrend.com"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <span className="text-sm font-medium">View Website</span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M10 6H6a2 2 0 00-2 2v10a2 2 0 002 2h10a2 2 0 002-2v-4M14 4h6m0 0v6m0-6L10 14"
                      />
                    </svg>
                  </a>
    
                  <button
                    className="block rounded-lg bg-indigo-600 px-5 py-3 text-sm font-medium text-white transition hover:bg-indigo-700 focus:outline-none focus:ring"
                    type="button"
                    onClick={handleAddTask}
                  >
                    Add Task
                  </button>
                </div>
              </div>
            </div>
          </header>
    
          <div className="flex w-max gap-4 bg-gray-200 my-2 p-4 rounded-lg ">
            <DropDown
              items={companies}
              label="Select Company"
              onChange={(companyId) => {
                setSelectedCompany(companyId);
                console.log(companyId);
              }}
            />
            <DropDown
              items={projects}
              label="Select Project"
              onChange={(projectId) => setSelectedProject(projectId)}
              disabled={!selectedCompany}
            />
          </div>
    
          {error && <p className="text-red-500 mb-4">{error}</p>}
          {isAddTaskFormOpen && (
            <AddTaskForm
              onClose={handleCloseForm}
              projectId={selectedProject}
              users={selectedCompany.users}
            />
          )}
    
   
            <DragDropContext onDragEnd={onDragEnd}>
              <div className="flex gap-4">
                {Object.entries(columns).map(([columnId, column]) => (
                  <TaskColumn
                    key={columnId}
                    id={columnId}
                    title={column.title}
                    tasks={column.tasks}
                  />
                ))}
              </div>
            </DragDropContext>

        </Layout> )
}

export default Tasks