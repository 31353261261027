import React from "react";
import { Draggable } from "react-beautiful-dnd";

const CampaignCard = ({ campaign, index }) => {



  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  }



  return (
    <Draggable draggableId={campaign._id.toString()} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          className="mb-4 p-4 bg-white bg-gray-50 rounded-lg border border-gray-200"
        >
          <h3 className="font-semibold mb-2">{campaign.title}</h3>
          <p className="text-sm text-gray-600 mb-2">{campaign.description}</p>
          <div className="mt-2 text-xs">
            <p>Start:      {formatDate(campaign.startDate)}</p>
            <p>End: {formatDate(campaign.endDate)}</p>
            <p>Budget: ${campaign.budget.toLocaleString()}</p>
          </div>
          <div className="mt-2 text-xs">
            <p>Reach: {campaign.metrics.reach.toLocaleString()}</p>
            <p>Engagement: {campaign.metrics.engagement.toLocaleString()}</p>
            <p>Conversions: {campaign.metrics.conversions.toLocaleString()}</p>
          </div>
        </div>
      )}
    </Draggable>
  );
};

export default CampaignCard;
