import React from 'react';
import { Lightbulb, Zap, Target, BarChart, Shield, Users } from 'lucide-react';
import { useNavigate } from 'react-router-dom';

const FeaturesSection = () => {

  const navigate = useNavigate()

  const features = [
    {
      icon: <Lightbulb className="w-8 h-8" />,
      title: "AI-Powered Innovation",
      description: "Transform your marketing with advanced machine learning algorithms that deliver personalized customer experiences and predictive analytics.",
      stats: "40% Increase in Conversion Rates"
    },
    {
      icon: <Target className="w-8 h-8" />,
      title: "Precision Targeting",
      description: "Reach your ideal customers with data-driven targeting that combines behavioral analysis with AI-powered audience insights.",
      stats: "85% Better Campaign Performance"
    },
    {
      icon: <BarChart className="w-8 h-8" />,
      title: "Real-Time Analytics",
      description: "Make informed decisions with comprehensive analytics dashboards and real-time performance monitoring.",
      stats: "24/7 Performance Tracking"
    }
  ];

  return (
    <section className="py-20 bg-[#223362]">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="text-center mb-20">
          <h2 className="text-3xl md:text-4xl font-bold text-white mb-6">
            Why Choose OuiTrend?
          </h2>
          <div className="h-1 w-32 bg-[#41B07D] mx-auto"></div>
        </div>

        {/* Features Grid */}
        <div className="grid md:grid-cols-3 gap-8">
          {features.map((feature, index) => (
            <div
              key={index}
              className="bg-white rounded-xl p-8 shadow-lg hover:shadow-2xl transition-all duration-300 hover:-translate-y-2"
            >
              <div className="text-[#41B07D] mb-6">
                {feature.icon}
              </div>
              
              <h3 className="text-2xl font-bold text-[#223362] mb-4">
                {feature.title}
              </h3>
              
              <p className="text-gray-600 mb-6">
                {feature.description}
              </p>
              
              <div className="bg-[#41B07D]/10 rounded-lg p-4">
                <p className="text-[#41B07D] font-bold text-lg">
                  {feature.stats}
                </p>
              </div>
            </div>
          ))}
        </div>

        {/* Bottom Stats */}
        <div className="grid grid-cols-2 md:grid-cols-4 gap-8 mt-20">
          <div className="text-center text-white">
            <p className="text-4xl font-bold text-[#41B07D] mb-2">500+</p>
            <p className="text-sm">Projects Completed</p>
          </div>
          <div className="text-center text-white">
            <p className="text-4xl font-bold text-[#41B07D] mb-2">95%</p>
            <p className="text-sm">Client Satisfaction</p>
          </div>
          <div className="text-center text-white">
            <p className="text-4xl font-bold text-[#41B07D] mb-2">50+</p>
            <p className="text-sm">AI Solutions Deployed</p>
          </div>
          <div className="text-center text-white">
            <p className="text-4xl font-bold text-[#41B07D] mb-2">24/7</p>
            <p className="text-sm">Support Available</p>
          </div>
        </div>

        {/* CTA */}
        <div className="mt-20 text-center">
          <div className="inline-flex items-center space-x-6">
            <button onClick={()=> navigate('/contact')} className="px-8 py-3 bg-[#41B07D] text-white rounded-lg hover:bg-[#41B07D]/90 transition-all duration-300 shadow-lg hover:shadow-xl font-medium">
              Get Started Today
            </button>
            <button onClick={()=> navigate('/faqs')} className="px-8 py-3 border-2 border-white text-white rounded-lg hover:bg-white hover:text-[#223362] transition-all duration-300 font-medium">
              Learn More
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeaturesSection;