import React from 'react';
import { Code, Bot, BarChart, Globe, Megaphone, Users, Database, PenTool } from 'lucide-react';

const ServicesSection = () => {
  const services = [
    {
      icon: <Bot className="w-6 h-6" />,
      title: "AI-Powered Marketing",
      description: "Leverage cutting-edge artificial intelligence to optimize your marketing campaigns, predict customer behavior, and deliver personalized experiences at scale."
    },
    {
      icon: <Code className="w-6 h-6" />,
      title: "Custom Software Development",
      description: "Transform your business with tailored software solutions, from enterprise applications to mobile apps, built with modern technologies and scalable architecture."
    },
    {
      icon: <Globe className="w-6 h-6" />,
      title: "Web Development",
      description: "Create stunning, responsive websites that convert visitors into customers. Our web solutions combine beautiful design with powerful functionality."
    },
    {
      icon: <Database className="w-6 h-6" />,
      title: "CRM & Automation",
      description: "Streamline your operations with custom CRM solutions and intelligent automation tools that help you manage leads, track analytics, and boost efficiency."
    },
    {
      icon: <Megaphone className="w-6 h-6" />,
      title: "Digital Marketing",
      description: "Comprehensive digital marketing strategies including SEO, PPC, and content marketing, all powered by data analytics and AI-driven insights."
    },
    {
      icon: <PenTool className="w-6 h-6" />,
      title: "Content Creation",
      description: "AI-assisted content creation that delivers engaging, SEO-optimized content across all platforms, from social media to blog posts and marketing materials."
    }
  ];

  return (
    <section className="py-20 bg-gradient-to-br from-gray-50 to-white">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header */}
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-4xl font-bold text-[#223362] mb-6">
            Innovative Solutions for Modern Businesses
          </h2>
          <div className="h-1 w-32 bg-[#41B07D] mx-auto mb-6"></div>
          <p className="text-xl text-gray-600">
            Combining AI technology with industry expertise to deliver comprehensive digital solutions that drive growth and transform businesses in the digital age.
          </p>
        </div>

        {/* Services Grid */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          {services.map((service, index) => (
            <div
              key={index}
              className="bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 p-8 group hover:scale-105 hover:bg-gradient-to-br hover:from-[#223362] hover:to-[#41B07D]"
            >
              <div className="flex items-center mb-4">
                <div className="w-12 h-12 rounded-lg bg-[#41B07D]/10 flex items-center justify-center group-hover:bg-white/10">
                  <div className="text-[#41B07D] group-hover:text-white transition-colors duration-300">
                    {service.icon}
                  </div>
                </div>
                <h3 className="text-xl font-bold text-[#223362] ml-4 group-hover:text-white transition-colors duration-300">
                  {service.title}
                </h3>
              </div>
              <p className="text-gray-600 group-hover:text-gray-100 transition-colors duration-300">
                {service.description}
              </p>
              <div className="mt-6 flex items-center">
                <a
                  href="/services"
                  className="text-[#41B07D] font-medium group-hover:text-white transition-colors duration-300 flex items-center"
                >
                  Learn more
                  <svg
                    className="w-5 h-5 ml-2"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 5l7 7-7 7"
                    />
                  </svg>
                </a>
              </div>
            </div>
          ))}
        </div>

        {/* Bottom CTA */}
        <div className="mt-16 text-center">
          <a
            href="/contact"
            className="inline-flex items-center px-8 py-3 bg-[#223362] text-white rounded-lg hover:bg-[#223362]/90 transition-all duration-300 shadow-lg hover:shadow-xl"
          >
            Start Your Digital Transformation
            <svg
              className="w-5 h-5 ml-2"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M17 8l4 4m0 0l-4 4m4-4H3"
              />
            </svg>
          </a>
        </div>
      </div>
    </section>
  );
};

export default ServicesSection;