import React from 'react';
import { Bot, Code, BarChart, Zap, Cloud, Shield, PenTool, ArrowRight, Check } from 'lucide-react';

const SolutionsPage = () => {
  const solutions = [
    {
      category: "AI-Powered Marketing",
      description: "Transform your marketing strategy with data-driven insights and automated optimization.",
      icon: <Bot className="w-6 h-6" />,
      solutions: [
        {
          title: "Predictive Analytics",
          description: "Leverage AI to forecast trends and customer behavior, enabling proactive marketing strategies.",
          features: [
            "Customer behavior analysis",
            "Trend prediction",
            "ROI forecasting",
            "Market opportunity identification"
          ]
        },
        {
          title: "Marketing Automation",
          description: "Streamline your marketing processes with intelligent automation that adapts to performance.",
          features: [
            "Campaign automation",
            "Lead scoring",
            "Personalized content delivery",
            "A/B testing optimization"
          ]
        },
        {
          title: "Performance Optimization",
          description: "Continuously improve marketing performance through AI-driven insights and adjustments.",
          features: [
            "Real-time analytics",
            "Performance tracking",
            "Budget optimization",
            "Campaign refinement"
          ]
        }
      ]
    },
    {
      category: "Custom Software Development",
      description: "Build tailored software solutions that streamline operations and drive business growth.",
      icon: <Code className="w-6 h-6" />,
      solutions: [
        {
          title: "Web Applications",
          description: "Create powerful, scalable web applications that meet your specific business needs.",
          features: [
            "Custom dashboards",
            "Business process automation",
            "Integration capabilities",
            "Scalable architecture"
          ]
        },
        {
          title: "Mobile Solutions",
          description: "Develop mobile applications that enhance customer engagement and operational efficiency.",
          features: [
            "Cross-platform development",
            "Native applications",
            "Progressive web apps",
            "Mobile-first design"
          ]
        },
        {
          title: "Enterprise Software",
          description: "Build comprehensive enterprise solutions that integrate with your existing systems.",
          features: [
            "System integration",
            "Workflow automation",
            "Custom CRM solutions",
            "Data management"
          ]
        }
      ]
    },
    {
      category: "Digital Analytics",
      description: "Gain actionable insights through comprehensive data analysis and visualization.",
      icon: <BarChart className="w-6 h-6" />,
      solutions: [
        {
          title: "Business Intelligence",
          description: "Transform raw data into actionable insights for informed decision-making.",
          features: [
            "Custom dashboards",
            "Data visualization",
            "Performance metrics",
            "Trend analysis"
          ]
        },
        {
          title: "Customer Analytics",
          description: "Understand your customers better through detailed behavioral analysis.",
          features: [
            "Customer segmentation",
            "Journey mapping",
            "Engagement tracking",
            "Conversion analysis"
          ]
        }
      ]
    }
  ];

  return (
    <div className="min-h-screen pt-20">
      {/* Hero Section */}
      <section className="relative bg-gradient-to-br from-[#223362] to-[#41B07D] text-white py-20">
        <div className="absolute inset-0 bg-black/20"></div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl">
            <h1 className="text-4xl md:text-5xl font-bold mb-6">
              Innovative Solutions for Modern Business
            </h1>
            <p className="text-xl text-gray-100">
              Leverage AI-powered marketing and custom software solutions to transform 
              your business and drive sustainable growth.
            </p>
          </div>
        </div>
      </section>

      {/* Solutions Sections */}
      <section className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          {solutions.map((category, index) => (
            <div key={index} className="mb-20">
              <div className="flex items-center mb-8">
                <div className="w-12 h-12 bg-[#41B07D]/10 rounded-lg flex items-center justify-center text-[#41B07D] mr-4">
                  {category.icon}
                </div>
                <div>
                  <h2 className="text-3xl font-bold text-[#223362]">{category.category}</h2>
                  <p className="text-gray-600 mt-2">{category.description}</p>
                </div>
              </div>

              <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                {category.solutions.map((solution, sIndex) => (
                  <div 
                    key={sIndex}
                    className="bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 p-6"
                  >
                    <h3 className="text-xl font-bold text-[#223362] mb-4">{solution.title}</h3>
                    <p className="text-gray-600 mb-6">{solution.description}</p>
                    
                    <ul className="space-y-3">
                      {solution.features.map((feature, fIndex) => (
                        <li key={fIndex} className="flex items-center text-gray-600">
                          <Check className="w-5 h-5 text-[#41B07D] mr-2" />
                          {feature}
                        </li>
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-[#223362] py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold text-white mb-6">
            Ready to Transform Your Business?
          </h2>
          <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
            Schedule a consultation to discuss how our solutions can help you achieve your business goals.
          </p>
          <a
            href="/contact"
            className="inline-flex items-center px-8 py-3 bg-[#41B07D] text-white rounded-lg hover:bg-[#41B07D]/90 transition-all duration-300 shadow-lg hover:shadow-xl"
          >
            Schedule a Consultation
            <ArrowRight className="ml-2 w-5 h-5" />
          </a>
        </div>
      </section>
    </div>
  );
};

export default SolutionsPage;