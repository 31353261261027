import React, { useState } from 'react';
import { Globe, ArrowRight, Tags, Calendar } from 'lucide-react';
import TransitionsSiteImage from "../../../assets/TransitionsBHSiteSS.png"
import MaluhiaSiteImage from "../../../assets/MaluhiaSiteSS.png"

const PortfolioPage = () => {
  const [activeFilter, setActiveFilter] = useState('all');

  const projects = [
    {
      id: 1,
      title: "Transitions Behavioral Health",
      url: "transitionsbehavioralhealth.com",
      image: TransitionsSiteImage, // Can be replaced with actual image
      category: "healthcare",
      description: "A comprehensive behavioral health platform providing essential resources and support for mental health services.",
      tags: ["Healthcare", "Web Application", "UI/UX Design"],
      completionDate: "2024",
      features: [
        "Patient Portal Integration",
        "Resource Management",
        "Appointment Scheduling",
        "HIPAA Compliant"
      ]
    },
    {
      id: 2,
      title: "Maluhia Hospice",
      url: "maluhiahospice.com",
      image: MaluhiaSiteImage, // Can be replaced with actual image
      category: "healthcare",
      description: "A compassionate and user-friendly platform for hospice care services, focusing on patient comfort and family support.",
      tags: ["Healthcare", "Website", "Patient Care"],
      completionDate: "2024",
      features: [
        "Service Information",
        "Family Resources",
        "Care Team Profiles",
        "Contact System"
      ]
    },
    {
      id: 3,
      title: "Teacher Builder Placement",
      url: "placement.teacherbuilder.com",
      image: "/api/placeholder/800/600", // Can be replaced with actual image
      category: "education",
      description: "An innovative platform connecting educational institutions with qualified teaching professionals.",
      tags: ["Education", "Web Platform", "Job Portal"],
      completionDate: "2024",
      features: [
        "Job Matching Algorithm",
        "Profile Management",
        "Institution Dashboard",
        "Application Tracking"
      ]
    }
  ];

  const filters = [
    { id: 'all', label: 'All Projects' },
    { id: 'healthcare', label: 'Healthcare' },
    { id: 'education', label: 'Education' }
  ];

  const filteredProjects = activeFilter === 'all' 
    ? projects 
    : projects.filter(project => project.category === activeFilter);

  return (
    <div className="min-h-screen bg-gradient-to-br from-gray-50 to-white pt-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        {/* Header Section */}
        <div className="text-center mb-16">
          <h1 className="text-4xl font-bold text-[#223362] mb-4">
            Our Portfolio
          </h1>
          <div className="h-1 w-32 bg-[#41B07D] mx-auto mb-6"></div>
          <p className="text-xl text-gray-600 max-w-3xl mx-auto">
            Explore our collection of successful projects where we've helped businesses 
            transform their digital presence through AI-powered solutions and custom software.
          </p>
        </div>

        {/* Filters */}
        <div className="flex justify-center gap-4 mb-12">
          {filters.map((filter) => (
            <button
              key={filter.id}
              onClick={() => setActiveFilter(filter.id)}
              className={`px-6 py-2 rounded-full transition-all duration-300 ${
                activeFilter === filter.id
                  ? 'bg-[#41B07D] text-white'
                  : 'bg-gray-100 text-[#223362] hover:bg-gray-200'
              }`}
            >
              {filter.label}
            </button>
          ))}
        </div>

        {/* Projects Grid */}
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8 mb-16">
          {filteredProjects.map((project) => (
            <div
              key={project.id}
              className="bg-white rounded-xl shadow-lg hover:shadow-xl transition-all duration-300 overflow-hidden group"
            >
              {/* Project Image */}
              <div className="relative overflow-hidden">
                <img
                  src={project.image}
                  alt={project.title}
                  className="w-full h-48 object-cover transform group-hover:scale-105 transition-transform duration-300"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                  <div className="absolute bottom-4 left-4 right-4">
                    <a
                      href={`https://${project.url}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-white flex items-center hover:text-[#41B07D] transition-colors"
                    >
                      <Globe className="w-4 h-4 mr-2" />
                      {project.url}
                    </a>
                  </div>
                </div>
              </div>

              {/* Project Info */}
              <div className="p-6">
                <h3 className="text-xl font-bold text-[#223362] mb-3">
                  {project.title}
                </h3>
                <p className="text-gray-600 mb-4">
                  {project.description}
                </p>

                {/* Tags */}
                <div className="flex flex-wrap gap-2 mb-4">
                  {project.tags.map((tag, index) => (
                    <span
                      key={index}
                      className="px-3 py-1 bg-[#41B07D]/10 text-[#41B07D] rounded-full text-sm"
                    >
                      {tag}
                    </span>
                  ))}
                </div>

                {/* Features */}
                <div className="space-y-2">
                  {project.features.map((feature, index) => (
                    <div key={index} className="flex items-center text-gray-600">
                      <ArrowRight className="w-4 h-4 mr-2 text-[#41B07D]" />
                      {feature}
                    </div>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>

        {/* CTA Section */}
        <div className="text-center py-16 bg-[#223362] rounded-xl">
          <h2 className="text-3xl font-bold text-white mb-6">
            Ready to Start Your Project?
          </h2>
          <p className="text-gray-300 mb-8 max-w-2xl mx-auto">
            Let's discuss how we can help transform your business with our custom solutions.
          </p>
          <button className="px-8 py-3 bg-[#41B07D] text-white rounded-lg hover:bg-[#41B07D]/90 transition-all duration-300 shadow-lg hover:shadow-xl">
            Contact Us Today
          </button>
        </div>
      </div>
    </div>
  );
};

export default PortfolioPage;