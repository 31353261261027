import React from 'react'
import { ArrowRight } from "lucide-react";
import { useNavigate } from 'react-router-dom';


const HeroSection = () => {
  const navigate = useNavigate();
    return (
        <section className="relative min-h-screen bg-gradient-to-br from-[#223362] to-[#41B07D]">
          {/* Overlay */}
          <div className="absolute inset-0 bg-black/20"></div>
    
          {/* Content Container */}
          <div className="relative container mx-auto px-4">
            <div className="grid lg:grid-cols-12 gap-8 items-center min-h-screen py-20">
              {/* Text Content */}
              <div className="lg:col-span-7 text-white space-y-8">
                <h1 className="text-4xl md:text-5xl xl:text-6xl font-extrabold leading-tight">
                  Empowering Growth Through Digital Experience
                </h1>
                
                <p className="text-xl text-gray-100 max-w-2xl">
                  At OuiTrend, we are San Antonio's premier destination for web
                  development, app development, and marketing services. Elevate your
                  online presence with our innovative solutions tailored for
                  success. Book your consultation today and unlock the potential to
                  your digital journey.
                </p>
    
                <div className="flex flex-wrap gap-4 pt-4">
                  <button onClick={()=> navigate('/contact')} className="px-8 py-3 bg-white text-[#223362] rounded-lg hover:bg-gray-100 transition duration-300 flex items-center font-medium shadow-lg hover:shadow-xl">
                    Request a Consultation
                    <ArrowRight className="ml-2 w-5 h-5" />
                  </button>
                  <button onClick={()=> navigate('/faqs')}className="px-8 py-3 border-2 border-white text-white rounded-lg hover:bg-white hover:text-[#223362] transition duration-300 font-medium">
                    Learn More
                  </button>
                </div>
              </div>
    
              {/* Image */}
              <div className="lg:col-span-5 relative">
                {/* Decorative elements */}
                <div className="absolute -z-10 -top-10 -left-10 w-72 h-72 bg-[#41B07D]/30 rounded-full blur-3xl"></div>
                <div className="absolute -z-10 -bottom-10 -right-10 w-72 h-72 bg-[#223362]/30 rounded-full blur-3xl"></div>
                
                {/* Main image */}
                <div className="relative z-10 transform hover:scale-105 transition duration-500">
                  <img
                    src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/hero/phone-mockup.png"
                    alt="mockup"
                    className="w-full h-auto object-contain"
                  />
                </div>
              </div>
            </div>
          </div>
    
          {/* Decorative bottom wave */}
          <div className="absolute bottom-0 left-0 right-0">
            <svg 
              viewBox="0 0 1440 200" 
              xmlns="http://www.w3.org/2000/svg" 
              className="fill-white"
            >
              <path 
                d="M0,160L48,154.7C96,149,192,139,288,138.7C384,139,480,149,576,144C672,139,768,117,864,112C960,107,1056,117,1152,122.7C1248,128,1344,128,1392,128L1440,128L1440,200L1392,200C1344,200,1248,200,1152,200C1056,200,960,200,864,200C768,200,672,200,576,200C480,200,384,200,288,200C192,200,96,200,48,200L0,200Z"
              />
            </svg>
          </div>
        </section>
      );
}

export default HeroSection