import React from 'react';
import { FileText, ShieldCheck, AlertCircle, Scale } from 'lucide-react';

const TermsAndConditions = () => {
  const sections = [
    {
      icon: <FileText className="w-6 h-6" />,
      title: "Terms of Use",
      content: [
        "By accessing our website and services, you agree to these terms and conditions",
        "You must be at least 18 years old to use our services",
        "You are responsible for maintaining the confidentiality of your account",
        "We reserve the right to modify these terms at any time",
        "Continued use of our services constitutes acceptance of updated terms"
      ]
    },
    {
      icon: <Scale className="w-6 h-6" />,
      title: "Service Agreement",
      content: [
        "Services are provided on an 'as is' and 'as available' basis",
        "We maintain the right to modify or discontinue services without notice",
        "Payment terms are subject to separate service agreements",
        "Service delivery timelines may vary based on project scope",
        "Cancellation policies apply as per individual service contracts"
      ]
    },
    {
      icon: <ShieldCheck className="w-6 h-6" />,
      title: "Intellectual Property",
      content: [
        "All content and materials on our website are our intellectual property",
        "You may not use our content without express written permission",
        "Client retains ownership of their provided content and materials",
        "Deliverables ownership transfers upon full payment",
        "We retain the right to use non-confidential work in our portfolio"
      ]
    },
    {
      icon: <AlertCircle className="w-6 h-6" />,
      title: "Limitations & Liabilities",
      content: [
        "We are not liable for indirect, consequential, or incidental damages",
        "Our liability is limited to the amount paid for services",
        "We do not guarantee specific results from our services",
        "Force majeure clauses apply to service disruptions",
        "You agree to indemnify us against third-party claims"
      ]
    }
  ];

  return (
    <div className="min-h-screen pt-20">
      {/* Hero Section */}
      <section className="relative bg-gradient-to-br from-[#223362] to-[#41B07D] text-white py-16">
        <div className="absolute inset-0 bg-black/20"></div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h1 className="text-4xl font-bold mb-4">Terms & Conditions</h1>
          <p className="text-xl text-gray-100 max-w-2xl mx-auto">
            Please read these terms and conditions carefully before using our services.
          </p>
        </div>
      </section>

      {/* Last Updated */}
      <div className="bg-gray-50 py-4">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <p className="text-gray-600">Last Updated: February 12, 2025</p>
        </div>
      </div>

      {/* Main Content */}
      <section className="py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="prose max-w-none">
            <p className="text-gray-600 mb-12">
              These terms and conditions constitute a legally binding agreement between you 
              ("the Client") and OuiTrend ("we," "us," or "our") regarding the use of our 
              services and website.
            </p>

            {sections.map((section, index) => (
              <div key={index} className="mb-12">
                <div className="flex items-center mb-6">
                  <div className="w-12 h-12 bg-[#41B07D]/10 rounded-lg flex items-center justify-center text-[#41B07D] mr-4">
                    {section.icon}
                  </div>
                  <h2 className="text-2xl font-bold text-[#223362]">{section.title}</h2>
                </div>
                <ul className="list-none space-y-3 pl-16">
                  {section.content.map((item, i) => (
                    <li key={i} className="text-gray-600 flex items-center">
                      <div className="w-1.5 h-1.5 rounded-full bg-[#41B07D] mr-2"></div>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            ))}

            {/* Governing Law */}
            <div className="mt-12 p-6 bg-gray-50 rounded-lg">
              <h2 className="text-2xl font-bold text-[#223362] mb-4">Governing Law</h2>
              <p className="text-gray-600">
                These terms and conditions are governed by and construed in accordance with 
                the laws of Texas, United States. Any disputes relating to these terms and 
                conditions shall be subject to the exclusive jurisdiction of the courts of Texas.
              </p>
            </div>

            {/* Contact Section */}
            <div className="mt-8 p-6 bg-gray-50 rounded-lg">
              <h2 className="text-2xl font-bold text-[#223362] mb-4">Contact Us</h2>
              <p className="text-gray-600">
                If you have any questions about these Terms & Conditions, please contact us at{' '}
                <a href="mailto:legal@ouitrend.com" className="text-[#41B07D] hover:underline">
                  legal@ouitrend.com
                </a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default TermsAndConditions;