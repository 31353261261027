import React from "react";
import { Droppable } from "react-beautiful-dnd";
import CampaignCard from "./campaignCard";

const CampaignColumn = ({ id, title, campaigns }) => {
  return (
    <Droppable droppableId={id}>
      {(provided, snapshot) => (
        <div
          {...provided.droppableProps}
          ref={provided.innerRef}
          className={`bg-gray-200 h-max p-4 rounded-lg w-1/4 overflow-y-auto ${
            snapshot.isDraggingOver ? 'bg-gray-300' : ''
          }`}
        >
          <h2 className="font-semibold mb-4">
            {title}
          </h2>
         
            {campaigns.map((campaign, index) => (
              <CampaignCard
                key={campaign._id}
                campaign={campaign}
                index={index}
              />
            ))}
       
        </div>
      )}
    </Droppable>
  );
};

export default CampaignColumn;
