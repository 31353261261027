import React, { useState } from "react";
import { redirect } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setLogin } from "../../features/auth/authSlice";
import * as yup from "yup";
import { Formik } from "formik";
import logo from "../../assets/ouitrend.png";

import bg from './assets/patron.png'

import { fetchCompanies } from "../../features/companies/companiesSlice";
import { ArrowRight, Mail, Lock } from "lucide-react";


const url = "http://localhost:3001";

const LoginPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const registration = () => redirect("/registration");

  const loginSchema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Email is required"),
    password: yup.string().required("Password is required"),
  });

  const initialValuesLogin = {
    email: "",
    password: "",
  };

  const signIn = async (values) => {
    const response = await fetch(`${url}/auth/signin`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(values),
    });
    return response.json();
  };

  const handleSignIn = async (values, onSubmitProps) => {
    const loggedIn = await signIn(values);
    onSubmitProps.resetForm();
    
    if (loggedIn) {
      dispatch(setLogin({
        user: loggedIn.user,
        token: loggedIn.token,
      }));
      
      if(loggedIn.user.role === 'admin') {
        dispatch(fetchCompanies());
      }
      
      navigate("/app/admin/home");
    }
  };

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 to-white flex items-center justify-center p-6 relative">
      <img
        src={require("./assets/patron.png")}
        alt="Background Pattern"
        className="absolute inset-0 w-full h-full object-cover opacity-10 pointer-events-none"
      />
      <div className="bg-white w-full max-w-md rounded-2xl shadow-xl p-8">
        <div className="space-y-6">
          <div className="text-center">
            <img 
              src={logo}
              alt="Digital Marketing Agency Logo" 
              className="mx-auto h-30 w-auto"
            />
            <h1 className="text-3xl font-bold text-gray-900">Welcome back</h1>
            <p className="mt-2 text-gray-600">Sign in to your account</p>
          </div>

          <Formik
            onSubmit={handleSignIn}
            initialValues={initialValuesLogin}
            validationSchema={loginSchema}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
            }) => (
              <form onSubmit={handleSubmit} className="space-y-6">
                <div className="space-y-4">
                  <div>
                    <div className="relative">
                      <Mail className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
                      <input
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Email address"
                        className="pl-10 w-full rounded-lg border border-gray-200 py-3 px-4 text-sm outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-500/20"
                      />
                    </div>
                    {touched.email && errors.email && (
                      <p className="mt-1 text-sm text-red-500">{errors.email}</p>
                    )}
                  </div>

                  <div>
                    <div className="relative">
                      <Lock className="absolute left-3 top-3 h-5 w-5 text-gray-400" />
                      <input
                        type="password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Password"
                        className="pl-10 w-full rounded-lg border border-gray-200 py-3 px-4 text-sm outline-none focus:border-blue-500 focus:ring-2 focus:ring-blue-500/20"
                      />
                    </div>
                    {touched.password && errors.password && (
                      <p className="mt-1 text-sm text-red-500">{errors.password}</p>
                    )}
                  </div>
                </div>

                <div className="flex items-center justify-between">
                  <div className="flex items-center">
                    <input
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-blue-600 focus:ring-blue-500"
                    />
                    <label className="ml-2 text-sm text-gray-600">Remember me</label>
                  </div>
                  <a href="#" className="text-sm font-medium text-blue-600 hover:text-blue-800">
                    Forgot password?
                  </a>
                </div>

                <button
                  type="submit"
                  className="group relative w-full flex items-center justify-center py-3 px-4 bg-blue-600 text-white rounded-lg font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors"
                >
                  Sign in
                  <ArrowRight className="ml-2 h-4 w-4 group-hover:translate-x-1 transition-transform" />
                </button>
              </form>
            )}
          </Formik>

          <div className="mt-6 text-center text-sm">
            <span className="text-gray-600">Don't have an account? </span>
            <a href="/registration" className="font-medium text-blue-600 hover:text-blue-800">
              Sign up
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;