import React from "react";
import { ArrowRight, Bot, Code, BarChart, Shield, Activity } from "lucide-react";
import HeroSection from "../components/home/HeroSection";
import ServicesSection from "../components/home/ServicesSection";
import FeaturesSection from "../components/home/FeaturesSection";
import CTASection from "../components/home/CTASection";

const HomePage = () => {


  return (
    <div className="min-h-screen">
      {/* Hero Section */}
      <HeroSection/>

      {/* Services Section */}
      <ServicesSection/>

      {/* Features Grid */}
      <FeaturesSection/>

      {/* CTA Section */}
    <CTASection/>
    </div>
  );
};

export default HomePage;