import React from "react";
import { ArrowRight, Code, Lightbulb, Target, Users, Check, Zap, Shield } from "lucide-react";

const AboutPage = () => {
  const stats = [
    { number: "5+", label: "Years Experience", suffix: "" },
    { number: "100", label: "Projects Completed", suffix: "+" },
    { number: "95", label: "Client Satisfaction", suffix: "%" },
    { number: "40", label: "Team Members", suffix: "+" }
  ];

  const values = [
    {
      icon: <Zap className="w-6 h-6" />,
      title: "Innovation First",
      description: "Pioneering AI-driven solutions that keep our clients ahead of the curve."
    },
    {
      icon: <Shield className="w-6 h-6" />,
      title: "Quality Assured",
      description: "Delivering excellence through rigorous testing and best practices."
    },
    {
      icon: <Target className="w-6 h-6" />,
      title: "Results Driven",
      description: "Focused on delivering measurable impact and ROI for our clients."
    },
    {
      icon: <Users className="w-6 h-6" />,
      title: "Client Partnership",
      description: "Building long-term relationships through trust and collaboration."
    }
  ];

  const capabilities = [
    "Custom Software Development",
    "AI-Powered Marketing Solutions",
    "Data Analytics & Insights",
    "Digital Transformation",
    "Cloud Infrastructure",
    "Mobile App Development",
    "UI/UX Design",
    "Systems Integration"
  ];

  return (
    <div className="min-h-screen pt-20">
      {/* Hero Section */}
      <section className="relative bg-gradient-to-br from-[#223362] to-[#41B07D] text-white py-24 overflow-hidden">
        <div className="absolute inset-0 bg-black/20"></div>
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="max-w-3xl">
            <h1 className="text-5xl md:text-6xl font-bold mb-6">
              Transforming <br />
              Digital Experiences
            </h1>
            <p className="text-xl text-gray-100 mb-8">
              San Antonio's premier technology partner, combining AI innovation 
              with expert software development since 2018.
            </p>
          </div>
        </div>
        {/* Decorative Elements */}
        <div className="absolute bottom-0 right-0 w-96 h-96 bg-white/10 rounded-full -translate-y-1/2 translate-x-1/2"></div>
        <div className="absolute top-0 left-0 w-96 h-96 bg-white/10 rounded-full -translate-x-1/2 -translate-y-1/2"></div>
      </section>

      {/* Stats Section */}
      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid grid-cols-2 md:grid-cols-4 gap-8">
            {stats.map((stat, index) => (
              <div key={index} className="text-center p-8 rounded-xl bg-gray-50">
                <h3 className="text-4xl font-bold text-[#223362] mb-2">
                  {stat.number}{stat.suffix}
                </h3>
                <p className="text-gray-600">{stat.label}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Main Content Section */}
      <section className="py-16 bg-gradient-to-br from-gray-50 to-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="grid lg:grid-cols-2 gap-16 items-center">
            <div className="space-y-8">
              <div>
                <h2 className="text-4xl font-bold text-[#223362] mb-6">
                  Our Story
                </h2>
                <div className="h-1 w-32 bg-[#41B07D] mb-8"></div>
                <div className="space-y-6 text-gray-600">
                  <p className="text-lg">
                    Founded in 2018 in San Antonio, Texas, OuiTrend began with a vision 
                    to revolutionize how businesses approach digital transformation. Our 
                    journey started in marketing, but we quickly evolved to meet our clients' 
                    growing needs for comprehensive technology solutions.
                  </p>
                  <p className="text-lg">
                    Today, we're proud to be at the forefront of AI-powered marketing and 
                    custom software development, serving clients across various industries 
                    with innovative solutions that drive real business growth.
                  </p>
                </div>
              </div>

              {/* Capabilities List */}
              <div className="grid grid-cols-2 gap-4">
                {capabilities.map((capability, index) => (
                  <div key={index} className="flex items-center space-x-2">
                    <Check className="w-5 h-5 text-[#41B07D]" />
                    <span className="text-gray-700">{capability}</span>
                  </div>
                ))}
              </div>
            </div>

            <div className="relative">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-7">
                  <img
                    className="w-full h-full object-cover rounded-lg shadow-xl"
                    src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-1.png"
                    alt="Team collaboration"
                  />
                </div>
                <div className="col-span-5 space-y-4">
                  <img
                    className="w-full h-48 object-cover rounded-lg shadow-xl"
                    src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-2.png"
                    alt="Office space"
                  />
                  <img
                    className="w-full h-48 object-cover rounded-lg shadow-xl"
                    src="https://flowbite.s3.amazonaws.com/blocks/marketing-ui/content/office-long-2.png"
                    alt="Team meeting"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Values Section */}
      <section className="py-16 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold text-[#223362] mb-6">Our Values</h2>
            <div className="h-1 w-32 bg-[#41B07D] mx-auto"></div>
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-4 gap-8">
            {values.map((value, index) => (
              <div 
                key={index} 
                className="bg-gray-50 p-8 rounded-xl hover:shadow-lg transition duration-300"
              >
                <div className="w-12 h-12 bg-[#41B07D]/10 rounded-lg flex items-center justify-center text-[#41B07D] mb-6">
                  {value.icon}
                </div>
                <h3 className="text-xl font-semibold text-[#223362] mb-4">{value.title}</h3>
                <p className="text-gray-600">{value.description}</p>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-[#223362] py-16">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold text-white mb-6">
            Ready to Transform Your Business?
          </h2>
          <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
            Let's discuss how our AI-powered solutions can help you achieve your goals.
          </p>
          <button className="px-8 py-3 bg-[#41B07D] text-white rounded-lg hover:bg-[#41B07D]/90 transition duration-300 shadow-lg hover:shadow-xl flex items-center mx-auto">
            Schedule a Consultation
            <ArrowRight className="ml-2 w-5 h-5" />
          </button>
        </div>
      </section>
    </div>
  );
};

export default AboutPage;